import { BrowserRouter, Routes, Route } from "react-router-dom"

//PAGE
import Home from './pages/Home'
import Project from './pages/Project'
import Projects from "./pages/Projects";
import Professional from "./pages/Professional";
import Mentions from './pages/Mentions'
import NotFound from './pages/NotFound';

//DATA
import { projects } from './data/projects'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home projects={projects} />} />
        <Route path='/projects' element={<Projects projects={projects} />} />
        <Route path='/projects/:projectName' element={<Project projects={projects} />} />
        <Route path='/professional' element={<Professional projects={projects} />} />
        <Route path='/mentions' element={<Mentions projects={projects}/>} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
