import React, { useState, useEffect } from 'react'

const HomeVideo = ({ section, setVideoLoad, videoLoad }) => {

    const [videoLoaded, setVideoLoaded] = useState(false);

    useEffect(() => {
        const videoElement = document.getElementById('home-video');

        const handleVideoLoad = () => {
            setVideoLoaded(true);
        };

        if (videoElement) {
            videoElement.addEventListener('canplaythrough', handleVideoLoad);
            return () => {
                videoElement.removeEventListener('canplaythrough', handleVideoLoad);
            };
        }
    }, []);

    useEffect(() => {
        if (videoLoaded) {
            setVideoLoad(true);
            console.log(videoLoad);
        }
    }, [videoLoaded, setVideoLoad, videoLoad]);


    return (
        <div className={`${section === 0 || section === 1 ? 'opacity-100' : 'opacity-0'} absolute h-full w-full duration-500`}>
            <video
                id='home-video'
                className={`h-full w-full object-cover`}
                autoPlay
                playsInline
                muted
                loop
                preload="auto"
            >
                <source src='https://storage.googleapis.com/manoto-project/videos/home-video.mp4' type='video/mp4' />
            </video>
        </div>
    )
}

export default HomeVideo