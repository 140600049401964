import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// COMPONENTS
import Navbar from "../components/Navbar";
import Footer from '../components/Footer'

// IMPORT ICONS
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa'

//SLICK SLIDER
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Project = ({ projects }) => {

    const navigate = useNavigate();
    const { projectName } = useParams();
    const formattedProjectName = projectName.replace(/-/g, ' ');

    const [hScreenHeight, setHScreenHeight] = useState(window.innerHeight);
    const [section, setSection] = useState(0);
    const [homeCount, setHomeCount] = useState(0)
    const [currentPosition, setCurrentPosition] = useState(0)

    const sliderRef = useRef(null);
    const galleryRef = useRef(null);
    const planRef = useRef(null);

    const [gallery, setGallery] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0); // Görüntülenen resmin indeksi
    const [downArrow, setDownArrow] = useState(false);

    // DOWN ANIMATION
    useEffect(() => {
        const interval = setInterval(() => {
            setDownArrow(prevState => !prevState); // Aşağı oku tersine çevir (görünürse gizle, gizliyse görünür yap)
        }, 1000); // 1000 milisaniye (1 saniye) aralıklarla çalışır

        // Component çözümlendiğinde setInterval'i temizleyin.
        return () => clearInterval(interval);
    }, []);

    const openGallery = (index) => {
        setGallery(true);
        setCurrentIndex(index);
        // Galeri açıldığında slider'ı başlat ve belirlediğiniz indekse git
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index); // Slider'ı belirtilen indekse gitmek için kullanabilirsiniz
        }

        setTimeout(() => {
            if (galleryRef.current) {
                galleryRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            setHScreenHeight(window.innerHeight)
        }, 500);
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         setHScreenHeight(window.innerHeight)
    //     }, 500);
    // });

    // SCROLL UP
    // useEffect(() => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth',
    //     });
    // }, []);

    // COUNT HOMETEXT
    useEffect(() => {
        const interval = setInterval(() => {
            if (homeCount < 7) {
                setHomeCount(homeCount + 1);
                // console.log('homeCount', homeCount)
            }
        }, 500);
        return () => clearInterval(interval);
    }, [homeCount]);

    useEffect(() => {
        // Scroll olay dinleyicisini ekleyin
        const handleScroll = () => {
            const updatedPosition = window.scrollY; // Get the current scroll position
            setCurrentPosition(updatedPosition); // Update the state
        };

        window.addEventListener('scroll', handleScroll);

        // Bileşen sona erdiğinde olay dinleyiciyi kaldırın
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hScreenHeight, section]);

    const handleNavigation = (path) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Animasyonlu bir şekilde kaydırma yapmak için
        });
        navigate(path)
    }

    useEffect(() => {
        // Scroll olay dinleyicisini ekleyin
        const handleScroll = () => {
            const currentPosition = window.scrollY;
            if (currentPosition === 0) {
                setSection(0);
            } else if (currentPosition >= 0 && currentPosition < hScreenHeight) {
                setSection(3);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Bileşen sona erdiğinde olay dinleyiciyi kaldırın
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hScreenHeight, section]);

    const filteredProject = projects.find(project => project.name === formattedProjectName);

    let nextProject = null;
    let prevProject = null;

    if (filteredProject) { // Eğer proje bulunursa
        const filteredProjectIndex = projects.indexOf(filteredProject);

        if (filteredProjectIndex < projects.length - 1) {
            nextProject = projects[filteredProjectIndex + 1];
        }
        if (filteredProjectIndex > 0) {
            prevProject = projects[filteredProjectIndex - 1];
        }
    }

    if (!filteredProject) {
        return <div>Project not found.</div>;
    }

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const nextPlan = () => {
        if (planRef.current) {
            planRef.current.slickNext();
        }
    }

    const prevPlan = () => {
        if (planRef.current) {
            planRef.current.slickPrev();
        }
    }

    const settingsGallery = {
        dots: false,
        infinite: true, // Sonsuz döngü
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };
    return (
        <div className='relative'>
            <Navbar section={gallery ? 2 : section} currentPosition={currentPosition} homeCount={homeCount} gallery={gallery} projects={projects} />
            {/* PROJECT COVER */}
            <div style={{ height: `${hScreenHeight}px` }} className={`${gallery ? 'hidden' : ''} w-full h-full relative`}>
                <img className='absolute w-full h-full object-cover -z-20' src={filteredProject.cover} alt={filteredProject.name}></img>
                <div className='absolute w-full h-full bg-black/60 -z-10'></div>
                <div className='w-full h-full flex flex-row justify-center items-center text-4xl md:text-6xl text-white relative '>
                    <div className='flex lex-row w-full relative md:items-center '>
                        <div onClick={() => handleNavigation('/projects')} className={`text-sm md:text-xl hover:opacity-100 cursor-pointer duration-300 ml-5 self-center ${homeCount >= 3 ? 'opacity-75' : 'opacity-0 pr-20'}`}> Back</div>
                        <div className="px-5 md:px-20 w-full m-auto max-w-screen-2xl">
                            <div className={`w-full h-full duration-500 ${homeCount >= 4 ? 'opacity-1' : 'opacity-0 md:pl-40'}`}>{filteredProject.name}</div>
                        </div>
                    </div>
                </div>

                {/* SCROLL DOWN ANIMATION */}
                <div className={`${homeCount >= 5 ? 'opacity-100' : 'opacity-0 mb-20'} duration-500 absolute bottom-2 left-1/2 transform -translate-x-1/2 text-white`}>
                    <div className="h-16 w-[2px] bg-white/20 absolute bottom-0 rounded-xl">
                        <div className="h-4 bg-white/60 w-[2px] absolute top-0 animate-line rounded-xl"></div>
                    </div>
                </div>

                {/* FOOTBAR */}
                <div className='absolute h-[120px] w-full px-5 2xl:px-0 max-w-screen-2xl mx-auto left-0 right-0 bottom-0 justify-between z-30 text-white flex flex-row items-center'>
                    <div onClick={() => setGallery(true)} className="flex flex-row gap-5 hover:cursor-pointer">
                        Gallery
                    </div>

                    <div className={`${gallery ? 'hidden' : ''} relative`}>
                        <div style={{
                            height: `${homeCount === 0 ? `${0}px` : `${420 - currentPosition}px`} `,
                        }} className={`absolute bottom-[-60px] duration-500 w-[2px] rounded-full bg-white/20 2xl:bg-white/80 z-50 mr-[-10px]`}>
                        </div>
                    </div>
                </div>
            </div>

            {/* PROJECT PLAN */}
            <div className={`${gallery || filteredProject.plan === 0 ? 'hidden' : ''} h-full w-full max-w-screen-2xl m-auto flex flex-col md:flex-row md:flex-wrap py-2 md:py-5 px-5 md:px-0 pt-10`}>
                <div className='flex flex-col md:w-96 m-auto gap-2 px-2 lg:px-0'>
                    <div className='custom-font-SemiBold text-lg'>{filteredProject.name}</div>
                    <div className='custom-font-light text-sm md:text-base'>{filteredProject.brief}</div>
                </div>
                <div className='w-full md:w-1/2 relative'>
                    <div onClick={() => prevPlan()} className={`${filteredProject.plan.length === 1 ? 'hidden' : ''} absolute h-6 w-6 md:h-8 md:w-8 top-1/2 transform -translate-y-1/2 left-0 border-1 border-black z-20 cursor-pointer p-1 md:p-2 rounded-md md:hover:scale-150 duration-500`}>
                        <FaLongArrowAltUp className='w-full h-full text-black -rotate-90' />
                    </div>
                    <div onClick={() => nextPlan()} className={`${filteredProject.plan.length === 1 ? 'hidden' : ''} absolute h-6 w-6 md:h-8 md:w-8 top-1/2 transform -translate-y-1/2 right-0 border-1 border-black z-20 cursor-pointer p-1 md:p-2 rounded-md md:hover:scale-150 duration-500`}>
                        <FaLongArrowAltDown className='w-full h-full text-black -rotate-90' />
                    </div>
                    <div className='p-10'>
                        <Slider ref={planRef}>
                            {Array.isArray(filteredProject.plan) && filteredProject.plan.map((image, index) => (
                                <img
                                    key={index}
                                    className='object-contain'
                                    src={image}
                                    alt={`Resim ${index + 1}`}
                                />
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>

            {/* PROJECTS GALLERY */}
            <div className={`${gallery ? 'hidden' : ''} h-full w-full max-w-screen-2xl m-auto grid grid-cols-2 py-2 md:py-5 gap-2 md:gap-5 px-2 2xl:px-0`}>
                {filteredProject.image.map((item, index) => (
                    <div key={index} onClick={() => { openGallery(index) }} className="duration-500 cursor-pointer">
                        <img className='object-cover w-full h-full' src={item} loading="lazy" alt={`Thumbnail ${index}`} />
                    </div>
                ))}
            </div>
            {/* NEXT PREV PROJECT */}
            <div className={`${gallery ? 'hidden' : ''} flex flex-col w-full max-w-screen-2xl m-auto relative px-2 2xl:px-0 py-5 duration-500 justify-between`}>

                {prevProject && (
                    <div className='group duration-500 text-sm shadow-md p-4 project-item'>
                        <a href={`/projects/${prevProject.name.split(' ').join('-')}`}>
                            <div className='flex flex-row gap-2 items-center'>
                                <img src={prevProject.cover} className='w-1/4 h-36 md:h-56 group-hover:opacity-100 duration-500 object-cover'></img>
                                <div className='md:text-lg'>{prevProject.name}</div>
                                <div className='text-xs opacity-40'> Prev </div>
                            </div>
                        </a>
                    </div>
                )}
                {nextProject && (
                    <div className='group duration-500 text-sm shadow-md p-4 project-item'>
                        <a href={`/projects/${nextProject.name.split(' ').join('-')}`}>
                            <div className='flex flex-row-reverse gap-2 items-center'>
                                <img src={nextProject.cover} className='w-1/4 h-36 md:h-56 group-hover:opacity-100 duration-500 object-cover'></img>
                                <div className='md:text-lg'>{nextProject.name}</div>
                                <div className='text-xs opacity-40'> Next </div>
                            </div>
                        </a>
                    </div>
                )}
            </div>

            {/* PROJECT SLIDER */}
            <div ref={galleryRef} style={{ height: `${hScreenHeight}px` }} className={`${gallery ? '' : 'hidden'} h-full w-full relative max-w-screen-2xl m-auto`}>
                <div className='h-[80px]'></div>
                {/* <div onClick={() => setGallery(false)} className='absolute h-8 w-8 top-20 right-0 border-2 border-black z-20 p-2 rounded-md cursor-pointer'>
                    <AiOutlineClose className='w-full h-full text-black' />
                </div>
                <div onClick={() => prevSlide()} className='absolute h-8 w-8 top-1/2 transform -translate-y-1/2 left-0 border-2 border-black z-20 cursor-pointer p-2 rounded-md'>
                    <FaLongArrowAltUp className='w-full h-full text-black -rotate-90' />
                </div>
                <div onClick={() => nextSlide()} className='absolute h-8 w-8 top-1/2 transform -translate-y-1/2 right-0 border-2 border-black z-20 cursor-pointer p-2 rounded-md'>
                    <FaLongArrowAltDown className='w-full h-full text-black -rotate-90' />
                </div> */}
                <Slider {...settingsGallery} ref={sliderRef}>
                    {filteredProject.image.map((item, index) => (
                        <div style={{ height: `${hScreenHeight - 140}px` }} key={index} className="duration-500 cursor-pointer">
                            <img style={{ height: `${hScreenHeight - 140}px` }} className='object-contain h-full w-full' src={item} loading="lazy" alt={`Image ${index}`} />
                        </div>
                    ))}
                </Slider>
                <div className='absolute bottom-0 h-[60px] w-full flex flex-row justify-around custom-font'>
                    <div onClick={() => prevSlide()} className=' z-20 cursor-pointer p-2 rounded-md flex justify-center items-center md:text-xl hover:opacity-75 duration-500'>
                        <FaLongArrowAltDown className='rotate-90' />
                        {/* <FaLongArrowAltUp className='w-full h-full text-black -rotate-90' /> */}
                    </div>
                    <div onClick={() => setGallery(false)} className='z-20 p-2 rounded-md cursor-pointer flex justify-center items-center hover:opacity-75 duration-500'>
                        {formattedProjectName}
                        {/* <AiOutlineClose className='text-black text-[35px]' /> */}
                    </div>
                    <div onClick={() => handleNavigation('/projects')} className='z-20 p-2 rounded-md cursor-pointer flex justify-center items-center hover:opacity-75 duration-500'>
                        Projects
                    </div>

                    <div onClick={() => nextSlide()} className=' z-20 cursor-pointer p-2 rounded-md flex justify-center items-center md:text-xl hover:opacity-75 duration-500'>
                        {/* <FaLongArrowAltDown className='w-full h-full text-black -rotate-90' /> */}
                        <FaLongArrowAltDown className='-rotate-90' />
                    </div>
                </div>
            </div>

            <div className={`${gallery ? 'hidden' : ''}`}>
                <Footer section={true} />
            </div>
        </div>

    )
}

export default Project