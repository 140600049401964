import React, { useState, useEffect } from 'react'

const HomeText = ({ homeCount }) => {

    const isVisible = index => homeCount >= index;

    return (
        <div className='flex flex-col text-white gap-2 custom-font-light'>
            <div className={`${isVisible(1) ? 'opacity-1 duration-500' : 'md:pl-80 opacity-0'} text-2xl md:text-xl custom-font-SemiBold `}>"Manoto"</div>
            <div className={`${isVisible(2) ? 'opacity-1 duration-500' : 'md:pl-80 opacity-0'} text-sm md:text-sm text-gray-200 `}>The pleasant moment of sitting in front of the door, me and you.</div>
            <div className={`${isVisible(3) ? 'opacity-1 duration-500' : 'md:pl-80 opacity-0'} text-sm md:text-sm text-gray-200 `}>With two figures and two faces, with one life, me and you.</div>
            <div className={`${isVisible(4) ? 'opacity-1 duration-500' : 'md:pl-80 opacity-0'} text-sm md:text-sm text-gray-200 `}>Joyful and careless, free from distracting myths, me and you.</div>
            <div className={`${isVisible(5) ? 'opacity-1 duration-500' : 'md:pl-80 opacity-0'} text-sm md:text-sm text-gray-200 `}>Me and you, without us (ego), gather because of virtue (love)</div>
            <div className={`${isVisible(6) ? 'opacity-1 duration-500 text-2xl text-slate-200' : 'opacity-0'} pt-10 text-lg md:text-lg`}>Rûmî</div>
        </div>
    );
}

export default HomeText