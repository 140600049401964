import React, { useState, useEffect, useRef } from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// IMPORT BANNER
import MentionsBanner1 from '../media/mentions-banner-1.jpg'
import MentionsBanner2 from '../media/mentions-banner-2.jpg'

// IMPORT PDF 
import PDFpage1 from '../media/manoto-pdf-0.jpg'
import PDFpage2 from '../media/manoto-pdf-1.jpg'
import PDFpage3 from '../media/manoto-pdf-2.jpg'
import PDFpage4 from '../media/manoto-pdf-3.jpg'
import PDFpage5 from '../media/manoto-pdf-4.jpg'

const PDFManoto = [PDFpage1, PDFpage2, PDFpage3, PDFpage4, PDFpage5]

const Mentions = ({ projects }) => {

    // SCROLL UP
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <div className='bg-black'>
            <Navbar section={2} projects={projects} />
            <div className='h-[80px] w-full'></div>
            <div className="custom-font-SemiBold text-white max-w-screen-2xl w-full m-auto px-5 2xl:px-0 py-5 text-lg md:text-2xl "> Mentions</div>
            <div className='flex flex-col md:flex-row max-w-screen-2xl w-full m-auto px-5 md:px-0 gap-5'>
                <img src={MentionsBanner1} className='w-full md:w-1/2'></img>
                <img src={MentionsBanner2} className='w-full md:w-1/2'></img>
            </div>
            <div className='m-auto max-w-screen-2xl w-full pt-5 relative px-5 md:px-0 flex flex-col gap-5'>
                {PDFManoto.map((src, index) => (
                    <div key={index}>
                        <img className='object-contain m-auto' src={src} alt='' />
                    </div>
                ))}
                <div className='h-40'></div>
            </div>
            <Footer />
        </div>
    )
}

export default Mentions