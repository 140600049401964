import React from 'react'
import { FaHome } from "react-icons/fa";
import Logo from '../media/svg/logo-white-02.svg'

const NotFound = () => {
  return (
    <div className='h-screen bg-black flex justify-center items-center'>
        <div className='flex flex-col justify-center items-center relative'>
            <div className='absolute'>
                <img className='h-96 opacity-10 scale-150' src={Logo} alt='manoto-logo'></img>
            </div>
            <div className='text-[100px] md:text-[200px] text-white font-black z-10'>404</div>
            <div className='flex justify-center items-center z-10'>
                < a href='/'>
                    <div className='p-5 bg-gray-950 rounded-full border-2 border-transparent hover:border-white duration-500 cursor-pointer'>
                        <FaHome className='text-white' size={25} />
                    </div>
                </a>
            </div>
        </div>
    </div>
  )
}

export default NotFound