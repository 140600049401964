
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

// IMPORT ICONS
import { AiFillInstagram, AiFillLinkedin, AiFillMail } from 'react-icons/ai'

const Footer = ({ section }) => {

    const [footerState, setFooterState] = useState(false)

    const navigate = useNavigate();

    const openLink = (url) => {
        window.open(
            url,
            '_blank'
        );
    }

    const handleMailSend = (mail) => {
        const emailAddress = mail;
        window.location.href = `mailto:${emailAddress}`;
    }

    const handleNavigation = (path) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Animasyonlu bir şekilde kaydırma yapmak için
        });
        navigate(path)
    }

    useEffect(() => {
        const currentURL = window.location.href;
        // Projeler sayfasında mı olduğunuzu kontrol edin
        const isProjectsPage = currentURL.includes('/projects');
        if (!isProjectsPage) {
            let prevScrollY = window.scrollY;
            if (window.scrollY === 0) {
                setFooterState(false);
            }

            const handleScroll = () => {
                const scrollY = window.scrollY;
                if (scrollY > prevScrollY) {
                    setFooterState(true);
                } else {
                    setFooterState(false);
                }
                prevScrollY = scrollY;
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        } else if (isProjectsPage) {
            setFooterState(true)
        }
    }, []);


    return (
        <div className={` ${footerState ? 'h-24' : 'h-0'} ${section ? '' : 'fixed'} w-full bottom-0 bg-black z-50 duration-500 `}>
            <div className='max-w-screen-2xl m-auto w-full h-full flex flex-col md:flex-row px-5 2xl:px-0 py-2'>
                <div className='w-full md:w-1/2 flex flex-col gap-2 justify-center items-start m-auto text-white'>
                    <div className='flex flex-row gap-2 md:gap-5 custom-font text-xs md:text-sm'>
                        <Link to='/'>
                            <div className='cursor-pointer'>Home</div>
                        </Link>
                        <Link to='/#about'>
                            <div className='cursor-pointer'>About</div>
                        </Link>
                        <Link to='/projects'>
                            <div className='cursor-pointer'>Projects</div>
                        </Link>
                        <Link to='/professional'>
                            <div className='cursor-pointer'>Professional</div>
                        </Link>
                        <Link to='/#contact'>
                            <div className='cursor-pointer'>Contact</div>
                        </Link>
                    </div>
                    <div className='flex flex-row gap-2 md:gap-5'>
                        <AiFillMail onClick={() => { handleMailSend('info@manoto.com.tr') }} className='cursor-pointer hover:opacity-75 duration-500 text-[15px] md:text-[18px]' />
                        <AiFillInstagram onClick={() => openLink('https://www.instagram.com/manotointeriors')} className='cursor-pointer hover:opacity-75 duration-500 text-[15px] md:text-[18px]' />
                        <AiFillLinkedin onClick={() => openLink('https://www.linkedin.com/company/manoto-design/')} className='cursor-pointer hover:opacity-75 duration-500 text-[15px] md:text-[18px]' />
                    </div>
                </div>

                <div className='w-full md:w-1/2 flex flex-col justify-center items-end text-[8px] md:text-[10px] text-white'>
                    <div className='flex flex-col gap-2 text-end'>
                        <div className={`${footerState ? '' : 'pt-10'} duration-500`}>Copyright © 2023 | Manoto Interior Design | All Rights Reserved.</div>
                        <div className='cursor-pointer hover:underline' onClick={() => { openLink('https://www.mevzuat.gov.tr/MevzuatMetin/1.3.5846.pdf') }}>The rights to all content on Manoto.com.tr belong to Manoto Interior Design. Unauthorized use or copying will result in legal action.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer