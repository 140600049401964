import React, { useState, useEffect } from 'react'

// IMPORT COMPONENTS
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// IMPORT DATA 
import { references } from '../data/projects';

// IMPORT BUTTON
import MentionsButton from '../components/button/MentionsButton'

const Professional = ({ projects }) => {
    const [hScreenHeight, setHScreenHeight] = useState(window.innerHeight);
    const [reference, setReference] = useState(false);

    // SCROLL UP
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const downloadPdf1 = () => {
        // PDF dosyasının URL'sini burada belirtin
        const pdfUrl = 'https://drive.google.com/file/d/1ogdvg1um2lFXRdTr3Fr9r-MM7mlXxpSj/view?usp=drive_link';
        // PDF dosyasını indirmek için bir bağlantı oluşturun
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Dosyayı yeni bir pencerede açmak isterseniz
        link.setAttribute('download', 'manoto-interior-architecture.pdf'); // İndirilen dosyanın adı

        // Bağlantıya tıklama işlemini tetikleyin
        link.click();
    };

    const downloadPdf2 = () => {
        // PDF dosyasının URL'sini burada belirtin
        const pdfUrl = 'https://drive.google.com/file/d/1NPBw2_lhEqfUs0EjD2kb2jmMSk2TjSRV/view?usp=drive_link';

        // PDF dosyasını indirmek için bir bağlantı oluşturun
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Dosyayı yeni bir pencerede açmak isterseniz
        link.setAttribute('download', 'manoto-office-projects.pdf'); // İndirilen dosyanın adı

        // Bağlantıya tıklama işlemini tetikleyin
        link.click();
    };

    return (
        <div className='bg-black'>
            <Navbar section={1} projects={projects}/>
            <MentionsButton />
            <div id="professional" className="w-full h-full bg-black ">
                <div className="h-[80px] w-full bg-transparent"></div>
                <div className="h-[80px] custom-font-SemiBold text-white max-w-screen-2xl w-full m-auto px-5 2xl:px-0 text-lg md:text-2xl text-start py-5"> For Professional</div>
                <div style={{ height: reference ? '100%' : `${hScreenHeight}px` }} className="flex">
                    <div className="flex flex-col gap-5 text-white w-full  max-w-screen-2xl m-auto justify-center px-5 2xl:px-0 text-sm md:text-base">
                        <div className="flex flex-col gap-2">
                            <div className="text-gray-400">Click To Download PDF</div>
                            <div onClick={() => downloadPdf1()} className="hover:opacity-75 cursor-pointer">Manoto Interior Architecture PDF</div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="text-gray-400">Click To Download PDF</div>
                            <div onClick={() => downloadPdf2()} className="hover:opacity-75 cursor-pointer">Manoto Office Projects PDF</div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="text-gray-400">Click For References</div>
                            <div onClick={() => setReference(!reference)} className="hover:opacity-75 cursor-pointer">Reference</div>
                            <div className={`${reference ? ' h-full pt-20' : 'opacity-0 h-0 pt-40'} grid grid-cols-2 gap-2 duration-500 `}>
                                {references.map((data, index) => {
                                    if (reference) {
                                        return <div key={index}>{data}</div>;
                                    }
                                    return null; // reference false ise hiçbir şey gösterme
                                })}
                            </div>
                            <div className='h-24'></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Professional