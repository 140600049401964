import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const MapContainer = () => {
    const googleMapsApiKey = 'AIzaSyAJvdPa1OEZQwlX8u-ab4ZY_pqqkNY70hM'
    const [isTrue, setIsTrue] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTrue(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const mapStyles = {
        height: '100%',
        width: '100%',
    };

    const center = {
        lat: 39.899624471978264,
        lng: 32.86062029022259,
    };

    const customMapStyles = [
        {
            featureType: 'all',
            elementType: 'all',
            stylers: [
                {
                    invert_lightness: true, // Tüm renkleri ters çevirerek siyah-beyaz yapar
                },
                {
                    saturation: -100, // Renk doygunluğunu sıfıra ayarlar, tamamen siyah-beyaz yapar
                },
            ],
        },
    ];

    return (
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
            <GoogleMap
                mapContainerStyle={mapStyles}
                center={center}
                zoom={15}
                options={{
                    styles: customMapStyles, // Özel harita stilini burada belirtin
                }}
            >
                {isTrue ? <Marker position={center} /> : null}
            </GoogleMap>
        </LoadScript>
    );
};

export default MapContainer;