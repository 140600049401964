import React from 'react'

import LogoBlackSmall from '../../media/svg/logo-black-02.svg'


const MentionsButton = () => {
    return (
        <a href='/mentions'>
            <div className="fixed bottom-2 right-0 w-8 md:w-12 h-32 md:h-36 bg-white text-black custom-font-SemiBold flex flex-col justify-between items-center cursor-pointer z-50">
                <img className='w-5 md:w-8 pt-2' src={LogoBlackSmall}></img>
                <div className="transform -rotate-90 pl-16 md:pl-20 custom-font ">Mentions</div>
            </div>
        </a>
    )
}

export default MentionsButton