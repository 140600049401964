import React from 'react';

const ProjectGallery = ({ projects }) => {
    const commercialProject = projects.find(project => project.type === 'COMMERCIAL');
    const residentialProject = projects.find(project => project.type === 'RESIDENTIAL');

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-col md:flex-row gap-1 duration-500 relative px-5 2xl:px-0">
                <a href="/projects/#COMMERCIAL" className="flex-1">
                    <div className="w-full h-full flex flex-row md:flex-col gap-1 project-item relative">
                        <img className="w-full h-full object-cover" src={commercialProject.thumbnail} alt={commercialProject.name} />
                        <div className="absolute h-full w-full flex flex-col justify-center items-center cursor-pointer project-item md:hover:filter-none md:hover:bg-opacity-60">
                            <div className="absolute w-full h-full bg-black/40 hover:bg-black/0 z-10 transition duration-300"></div>
                            <div className="text-md md:text-2xl text-white z-20">Commercial</div>
                            <div className="custom-font text-xs md:text-lg text-white/60 z-20">INTERIOR DESIGN</div>
                        </div>
                    </div>
                </a>
                <a href="/projects/#RESIDENTIAL" className="flex-1">
                    <div className="w-full h-full flex flex-row md:flex-col gap-1 project-item relative">
                        <img className="w-full h-full object-cover" src={residentialProject.thumbnail} alt={residentialProject.name} />
                        <div className="absolute h-full w-full flex flex-col justify-center items-center cursor-pointer project-item md:hover:filter-none md:hover:bg-opacity-60">
                            <div className="absolute w-full h-full bg-black/40 hover:bg-black/0 z-10 transition duration-300"></div>
                            <div className="text-sm md:text-2xl text-white z-20">Residential</div>
                            <div className="custom-font text-xs md:text-lg text-white/60 z-20">INTERIOR DESIGN</div>
                        </div>
                    </div>
                </a>
                <a href="/projects" className="flex-1">
                    <div className="w-full h-full flex flex-row md:flex-col gap-1 project-item relative">
                        <img className="w-full h-full object-cover" src={projects[4].thumbnail} alt={projects[4].name} />
                        <div className="absolute h-full w-full flex flex-col justify-center items-center cursor-pointer project-item md:hover:filter-none md:hover:bg-opacity-60">
                            <div className="absolute w-full h-full bg-black/40 hover:bg-black/0 z-10 transition duration-300"></div>
                            <div className="text-sm md:text-2xl text-white z-20">All</div>
                            <div className="custom-font text-xs md:text-lg text-white/60 z-20">INTERIOR DESIGN</div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default ProjectGallery;
